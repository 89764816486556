import { DurationMs, Envelope, EpochMs, ErrorData, TypedPrimitive } from "@eatbetter/common-shared";

export interface BrowserFetchRequest {
  url: string;
  ts: EpochMs;
  /**
   * The max amount of time the fetch server will wait for the URL to load in the browser
   */
  browserTimeout?: DurationMs;

  /**
   * The amount of time after loading is complete to wait for any scripts, etc. to execute.
   */
  additionalDelayAfterLoad?: DurationMs;
}

export type BrowserFetchInternalError = ErrorData<
  "browserFetch/internalError",
  { id: string; url: string; error: string }
>;
export type BrowserFetchUrlError = ErrorData<
  "browserFetch/urlError",
  { id: string; url: string; error: string; navigationErrors: string[] }
>;
export type BrowserFetchTimeoutError = ErrorData<"browserFetch/timeout", { id: string; url: string }>;

export type BrowserFetchError = BrowserFetchInternalError | BrowserFetchUrlError | BrowserFetchTimeoutError;

export interface BrowserFetchData {
  id: string;
  url: string;
  finalUrl: string;
  html: string;
}

export type BrowserFetchResponse = Envelope<BrowserFetchData, BrowserFetchError>;

export type BrowserFetchInstanceName = TypedPrimitive<string, "browserFetchInstanceName">;

export const validBrowserFetchCountries = ["us", "england"] as const;
export type BrowserFetchCountry = (typeof validBrowserFetchCountries)[number];
export interface BrowserFetchPing {
  instanceName: BrowserFetchInstanceName;
  country: BrowserFetchCountry;
}

export function getBrowserFetchRequestHmacArgs(req: BrowserFetchRequest): { hmacKey: string; message: string } {
  return { hmacKey: fetchServerHmacKey, message: `${req.ts}:${req.url}` };
}

export function getBrowserFetchPingHmacArgs(req: BrowserFetchPing): { hmacKey: string; message: string } {
  return { hmacKey: fetchServerHmacKey, message: `${req.instanceName}:${req.country}` };
}

// Fetch server can only be accessed with
const fetchServerHmacKey =
  "QQZxNHJWcF2A8J4WgkcVHkiGJE4NZJ3gym2BmVruLDVv7jcxCkkjsocYssAM3uAqKKgZJhnqLhLJqLcxoiyZgqqeLGueQ9tCrTCi";
