import React, { useEffect, useImperativeHandle, useRef, useState } from "react";
import { NativeSyntheticEvent, ScrollView, StyleSheet, TextInputKeyPressEventData, View } from "react-native";
import { Opacity, globalStyleColors, globalStyleConstants } from "../GlobalStyles";
import { Spacer } from "../Spacer";
import Animated, {
  WithTimingConfig,
  useAnimatedStyle,
  useSharedValue,
  withDelay,
  withSequence,
  withTiming,
} from "react-native-reanimated";
import { IconCheck } from "../Icons";
import { TTertiary } from "../Typography";
import { GroceryItemTextInput } from "./GroceryItemTextInput";
import { GrocerySmartSuggestions } from "./GrocerySmartSuggestions";
import { log } from "../../Log";
import { GroceryListSuggestionWithListContext } from "../../lib/lists/ListsSelectors";
import { GroceryListSuggestion } from "@eatbetter/lists-shared";
import { addGroceryItemHorizontalSpace } from "./GroceryListStyleConstants";

const strings = {
  inputPlaceholder: "Add new grocery item",
  itemAdded: "Item added!",
};

const config = {
  paddingTop: globalStyleConstants.unitSize,
  paddingBottom: 0,
  toastHeight: 2.5 * globalStyleConstants.unitSize,
};

export interface GroceryListItemAddProps {
  inputText: string;
  onKeyPress: (e: NativeSyntheticEvent<TextInputKeyPressEventData>) => void;
  onChangeText: (v: string) => void;
  onDismiss: () => void;
  onKeyboardSubmit: () => void;
  autofocusTextInput?: boolean;
  suggestions: GroceryListSuggestion[];
  typeAheadSuggestions: GroceryListSuggestionWithListContext[];
  onPressSuggestion: (suggestion: GroceryListSuggestion, index: number, type: "Pill" | "Typeahead") => void;
}

export interface GroceryListItemAddImperativeHandle {
  scrollSuggestionsToTop: () => void;
  triggerItemAddedToast: () => void;
}

export const GroceryListItemAdd = React.forwardRef<GroceryListItemAddImperativeHandle, GroceryListItemAddProps>(
  (props, ref) => {
    const suggestionListRef = useRef<ScrollView>(null);
    const itemAddedToastRef = useRef<ItemAddedToastImperativeHandle>(null);

    useImperativeHandle(
      ref,
      () => ({
        scrollSuggestionsToTop: () => {
          suggestionListRef.current?.scrollTo({ y: 0 });
        },
        triggerItemAddedToast: () => {
          if (!itemAddedToastRef.current) {
            log.error(
              "GroceryListItemAdd: triggerItemAddedToast() failed because itemAddedToastRef is falsy. This should never happen.",
              { itemAddedToastRef: itemAddedToastRef.current }
            );
            return;
          }
          itemAddedToastRef.current.toast();
        },
      }),
      [suggestionListRef.current, itemAddedToastRef.current]
    );

    return (
      <View style={styles.container}>
        <View style={styles.scrollTopEdge}>
          <GroceryItemTextInput
            inputText={props.inputText}
            placeholderText={strings.inputPlaceholder}
            onKeyPress={props.onKeyPress}
            onChangeText={props.onChangeText}
            onSubmit={props.onKeyboardSubmit}
            blurOnSubmit={false}
            autofocus={props.autofocusTextInput}
          />
          <ItemAddedToast ref={itemAddedToastRef} />
        </View>
        <GrocerySmartSuggestions
          ref={suggestionListRef}
          type={props.inputText ? "Typeahead" : "Pill"}
          suggestions={props.inputText ? props.typeAheadSuggestions : props.suggestions}
          onPressSuggestion={props.onPressSuggestion}
        />
      </View>
    );
  }
);

interface ItemAddedToastImperativeHandle {
  toast: () => void;
}

const ItemAddedToast = React.forwardRef<ItemAddedToastImperativeHandle>((_, ref) => {
  const opacity = useSharedValue(Opacity.transparent);
  const [showToast, setShowToast] = useState(false);

  useImperativeHandle(
    ref,
    () => ({
      toast: () => setShowToast(true),
    }),
    [setShowToast]
  );

  useEffect(() => {
    if (!showToast) {
      return;
    }

    const timingConfig: WithTimingConfig = { duration: 500 };
    const toastDurationMs = 1500;

    opacity.value = withSequence(
      withTiming(Opacity.opaque, timingConfig),
      withDelay(toastDurationMs, withTiming(Opacity.transparent, timingConfig))
    );

    setShowToast(false);
  }, [showToast]);

  const toastAnimation = useAnimatedStyle(() => {
    return {
      opacity: opacity.value,
    };
  });

  return (
    <Animated.View style={[styles.toastWrap, toastAnimation]}>
      <IconCheck opacity="opaque" size={16} color={globalStyleColors.colorAccentCool} />
      <Spacer horizontal={0.5} />
      <TTertiary color={globalStyleColors.colorAccentCool}>{strings.itemAdded}</TTertiary>
    </Animated.View>
  );
});

const suggestionScrollEdge = {
  backgroundColor: globalStyleColors.colorGreyLight,
  shadowColor: globalStyleColors.colorGreyLight,
  shadowOpacity: 0.9,
  shadowRadius: 6,
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    // Moved to the children components because we were seeing weird tap target issues
    // with the left-most suggestion and this prevented the tap target from expanding left
    // when slop was applied
    //paddingHorizontal: 20,
    paddingTop: config.paddingTop,
  },
  toastWrap: {
    flexDirection: "row",
    height: config.toastHeight,
    alignItems: "center",
    paddingLeft: addGroceryItemHorizontalSpace,
  },
  scrollTopEdge: {
    ...suggestionScrollEdge,
    shadowOffset: { width: 0, height: 10 },
    zIndex: 1,
  },
});
