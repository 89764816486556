import { useScreen, withScreenContainer } from "../navigation/ScreenContainer";
import { ScreenView } from "../components/ScreenView";
import { ButtonRectangle, TextButton } from "../components/Buttons";
import { useCallback, useState } from "react";
import {
  selectPhotoFromCamera,
  selectPhotoFromLibrary,
  showCameraPermissionAlertAndNavToSettings,
} from "../lib/photos/SelectPhoto";
import { AppAddPhotoArgs } from "../lib/Types";
import { TBody, THeading2 } from "../components/Typography";
import { Spacer } from "../components/Spacer";
import { Image, View } from "react-native";
import { Spinner } from "../components/Spinner";
import React from "react";
import { IconEx } from "../components/Icons";
import { Pressable } from "../components/Pressable";
import { useDispatch } from "../lib/redux/Redux";
import { addRecipeFromPhotos } from "../lib/recipes/RecipesThunks";
import { displayUnexpectedErrorAndLog } from "../lib/Errors";
import { ContainerEnd } from "../components/Containers";

export const RecipeAddFromPhotosScreen = withScreenContainer("RecipeAddFromPhotosScreen", () => {
  const maxPhotos = 3;

  const [selectedPhotos, setSelectedPhotos] = useState<AppAddPhotoArgs[]>([]);
  const [processingPhotos, setProcessingPhotos] = useState(false);
  const [uploadProgress, setUploadProgress] = useState<Record<string, number>>({});
  const [waiting, setWaiting] = useState(false);
  const dispatch = useDispatch();
  const { nav } = useScreen();

  const selectFromLibrary = useCallback(async () => {
    if (selectedPhotos.length >= maxPhotos) {
      return;
    }

    setProcessingPhotos(true);
    const added = await selectPhotoFromLibrary();
    // this option will allow the user to select multiple photos at once if there are still at least 2 remaining available
    // this UI is a little more confusing - the user needs to tap a button in the upper right of a fairly busy
    // screen. I think most recipes will only be a single photo, or will be taken from the camera, so I think the
    // single option is probably better
    //const added = await selectPhotosFromLibrary(maxPhotos - selectedPhotos.length, { ordered: true });
    if (added.success) {
      setSelectedPhotos(cur => [...cur, added.result]);
    }
    setProcessingPhotos(false);
  }, [setProcessingPhotos, setSelectedPhotos, selectedPhotos]);

  const takePhoto = useCallback(async () => {
    if (selectedPhotos.length >= maxPhotos) {
      return;
    }

    setProcessingPhotos(true);
    const res = await selectPhotoFromCamera();
    if (res.success) {
      setSelectedPhotos(cur => [...cur, res.result]);
    }
    setProcessingPhotos(false);

    if (!res.success && res.result.reason === "accessDenied") {
      showCameraPermissionAlertAndNavToSettings();
    }
  }, [setProcessingPhotos, setSelectedPhotos, selectedPhotos]);

  const onUnselect = useCallback(
    (photo: AppAddPhotoArgs) => {
      setSelectedPhotos(cur => cur.filter(p => p.uri !== photo.uri));
    },
    [setSelectedPhotos]
  );

  const createRecipe = useCallback(async () => {
    try {
      const onProgress = (uri: string, progress: number) => {
        setUploadProgress(cur => ({ ...cur, [uri]: progress }));
      };

      await dispatch(addRecipeFromPhotos(selectedPhotos, onProgress, setWaiting));
      if (nav.canGoBack()) {
        nav.goBack();
      }
    } catch (err) {
      displayUnexpectedErrorAndLog("Error dispatching addRecipeFromPhotos", err);
    }
  }, [selectedPhotos, setUploadProgress, setWaiting]);

  return (
    <ScreenView header={{ type: "native", title: "Add from photos" }}>
      {selectedPhotos.length >= maxPhotos && <TBody>Max photos added</TBody>}
      {selectedPhotos.length < maxPhotos && (
        <View>
          <TextButton onPress={selectFromLibrary} text="Select from library" />
          <TextButton onPress={takePhoto} text="Take Photo" />
        </View>
      )}

      <Spacer vertical={2} />
      <THeading2>{selectedPhotos.length} Selected photos</THeading2>
      {selectedPhotos.map(sp => {
        return (
          <SelectedPhoto photo={sp} onDelete={onUnselect} key={sp.uri} uploadPercentComplete={uploadProgress[sp.uri]} />
        );
      })}
      {processingPhotos && <Spinner />}
      <ContainerEnd vertical>
        <ButtonRectangle
          type="submit"
          title="Create"
          onPress={createRecipe}
          disabled={selectedPhotos.length === 0}
          waiting={waiting}
        />
      </ContainerEnd>
    </ScreenView>
  );
});

const SelectedPhoto = React.memo(
  (props: { photo: AppAddPhotoArgs; uploadPercentComplete?: number; onDelete: (photo: AppAddPhotoArgs) => void }) => {
    return (
      <View style={{ flexDirection: "row" }}>
        <Image source={{ uri: props.photo.uri, width: 100, height: 100 }} />
        <Pressable onPress={() => props.onDelete(props.photo)}>
          <IconEx />
        </Pressable>
        {props.uploadPercentComplete !== undefined && <TBody>{props.uploadPercentComplete.toFixed(0)}</TBody>}
      </View>
    );
  }
);
