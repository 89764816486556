import { bottomThrow } from "@eatbetter/common-shared";
import React, { PropsWithChildren } from "react";
import { StyleSheet, View } from "react-native";
import { useNotificationUnreadCount } from "../lib/notifications/NotificationsSelectors";
import { log } from "../Log";
import { globalStyleColors, globalStyleConstants } from "./GlobalStyles";
import {
  IconBell,
  IconBellFilled,
  IconEditSquare,
  IconMore,
  IconOpenLink,
  IconPlus,
  IconMenu,
  IconShare,
  IconSpeakerMuted,
  IconSpeakerOn,
  IconSettings,
  IconText,
  IconSearchUsers,
} from "./Icons";
import { Pressable } from "./Pressable";
import { TBody } from "./Typography";
import { ButtonRectangle } from "./Buttons";
import { WalkthroughStep, WalkthroughStepProps } from "./Walkthrough";

const strings = {
  done: "Done",
  cancel: "Cancel",
  deleteAll: "Clear all",
  invite: "Invite",
  signOut: "Sign out",
  signUp: "Join Free",
};

interface HeaderRightPropsBase<TType extends string> {
  type: TType;
  onPress: () => void;
  tintColor?: string;
  marginRight?: number;
  disabled?: boolean;
}

type AddProps = HeaderRightPropsBase<"add">;
type CancelProps = HeaderRightPropsBase<"cancel">;
type DoneProps = HeaderRightPropsBase<"done">;
type DeleteAllProps = HeaderRightPropsBase<"deleteAll">;
type EditProps = HeaderRightPropsBase<"edit">;
type InviteProps = HeaderRightPropsBase<"invite">;
type MenuProps = HeaderRightPropsBase<"menu">;
type MoreMenuProps = HeaderRightPropsBase<"moreMenu">;
type NotificationCenterProps = HeaderRightPropsBase<"notificationCenter"> & { showWalkthrough?: WalkthroughStepProps };
type OpenLinkProps = HeaderRightPropsBase<"openLink">;
type SettingsProps = HeaderRightPropsBase<"settings">;
type ShareProps = HeaderRightPropsBase<"share">;
type SignOutProps = HeaderRightPropsBase<"signOut">;
type SignUpProps = HeaderRightPropsBase<"signUp">;
type SpeakerMutedProps = HeaderRightPropsBase<"speakerMuted">;
type SpeakerOnProps = HeaderRightPropsBase<"speakerOn">;
type TextSizeProps = HeaderRightPropsBase<"textSize">;
type FindUsersProps = HeaderRightPropsBase<"findUsers">;

interface TwoButtonsProps extends Omit<HeaderRightPropsBase<"twoButtons">, "onPress"> {
  left: Exclude<HeaderRightProps, TwoButtonsProps | ThreeButtonsProps>;
  right: Exclude<HeaderRightProps, TwoButtonsProps | ThreeButtonsProps>;
}

interface ThreeButtonsProps extends Omit<HeaderRightPropsBase<"threeButtons">, "onPress"> {
  left: Exclude<HeaderRightProps, TwoButtonsProps | ThreeButtonsProps>;
  middle: Exclude<HeaderRightProps, TwoButtonsProps | ThreeButtonsProps>;
  right: Exclude<HeaderRightProps, TwoButtonsProps | ThreeButtonsProps>;
}

export type HeaderRightProps =
  | AddProps
  | CancelProps
  | DeleteAllProps
  | DoneProps
  | EditProps
  | InviteProps
  | MenuProps
  | MoreMenuProps
  | NotificationCenterProps
  | OpenLinkProps
  | SettingsProps
  | ShareProps
  | SignOutProps
  | SignUpProps
  | SpeakerOnProps
  | SpeakerMutedProps
  | TextSizeProps
  | ThreeButtonsProps
  | TwoButtonsProps
  | FindUsersProps;

export const renderRightHeader = (props: HeaderRightProps) => {
  switch (props.type) {
    case "add": {
      return React.createElement(Add, props);
    }
    case "cancel": {
      return React.createElement(Cancel, props);
    }
    case "deleteAll": {
      return React.createElement(DeleteAll, props);
    }
    case "done": {
      return React.createElement(Done, props);
    }
    case "edit": {
      return React.createElement(Edit, props);
    }
    case "invite": {
      return React.createElement(Invite, props);
    }
    case "moreMenu": {
      return React.createElement(More, props);
    }
    case "notificationCenter": {
      return React.createElement(NotificationCenter, props);
    }
    case "openLink": {
      return React.createElement(OpenLink, props);
    }
    case "menu": {
      return React.createElement(Menu, props);
    }
    case "settings": {
      return React.createElement(Settings, props);
    }
    case "share": {
      return React.createElement(Share, props);
    }
    case "signOut": {
      return React.createElement(SignOut, props);
    }
    case "signUp": {
      return React.createElement(SignUp, props);
    }
    case "speakerMuted": {
      return React.createElement(SpeakerMuted, props);
    }
    case "speakerOn": {
      return React.createElement(SpeakerOn, props);
    }
    case "textSize": {
      return React.createElement(TextSize, props);
    }
    case "threeButtons": {
      return React.createElement(ThreeButtons, props);
    }
    case "twoButtons": {
      return React.createElement(TwoButtons, props);
    }
    case "findUsers": {
      return React.createElement(FindUsers, props);
    }
    default:
      bottomThrow(props, log);
  }
};

const Add = React.memo((props: AddProps) => {
  return (
    <RightHeaderButton {...props}>
      <IconPlus opacity="opaque" />
    </RightHeaderButton>
  );
});

const Cancel = React.memo((props: CancelProps) => {
  return <RightHeaderTextButton {...props} buttonText={strings.cancel} />;
});

const DeleteAll = React.memo((props: DeleteAllProps) => {
  return <RightHeaderTextButton {...props} buttonText={strings.deleteAll} />;
});

const Done = React.memo((props: DoneProps) => {
  return <RightHeaderTextButton {...props} buttonText={strings.done} />;
});

const Edit = React.memo((props: EditProps) => {
  return (
    <RightHeaderButton {...props}>
      <IconEditSquare opacity="opaque" />
    </RightHeaderButton>
  );
});

const Invite = React.memo((props: InviteProps) => {
  return (
    <View style={{ paddingRight: 8 }}>
      <ButtonRectangle size="small" type="submit" title={strings.invite} onPress={props.onPress} />
    </View>
  );
});

const Menu = React.memo((props: MenuProps) => {
  return (
    <RightHeaderButton {...props}>
      <IconMenu opacity="opaque" />
    </RightHeaderButton>
  );
});

const More = React.memo((props: MoreMenuProps) => {
  return (
    <RightHeaderButton {...props}>
      <IconMore size={28} opacity="opaque" />
    </RightHeaderButton>
  );
});

const NotificationCenter = React.memo((props: NotificationCenterProps) => {
  const badgeCount = useNotificationUnreadCount();

  const button = (
    <RightHeaderButton {...props}>
      {badgeCount > 0 && (
        <IconBellFilled badgeCount={badgeCount} color={props.showWalkthrough?.show ? "white" : props.tintColor} />
      )}
      {badgeCount < 1 && <IconBell opacity="opaque" />}
    </RightHeaderButton>
  );

  return (
    <>
      {!props.showWalkthrough && button}
      {!!props.showWalkthrough && (
        <WalkthroughStep
          show={props.showWalkthrough.show}
          message={props.showWalkthrough.message}
          buttonText={props.showWalkthrough.buttonText}
          onPressButton={props.showWalkthrough.onPressButton}
        >
          {button}
        </WalkthroughStep>
      )}
    </>
  );
});

const OpenLink = React.memo((props: OpenLinkProps) => (
  <RightHeaderButton {...props}>
    <IconOpenLink opacity="opaque" color={props.tintColor} />
  </RightHeaderButton>
));

const Settings = React.memo((props: SettingsProps) => {
  return (
    <RightHeaderButton {...props}>
      <IconSettings opacity="opaque" />
    </RightHeaderButton>
  );
});

const SignOut = React.memo((props: SignOutProps) => {
  return <RightHeaderTextButton {...props} buttonText={strings.signOut} />;
});

const SignUp = React.memo((props: SignUpProps) => {
  return (
    <View style={{ paddingRight: 8 }}>
      <ButtonRectangle size="small" type="submit" shadow title={strings.signUp} onPress={props.onPress} />
    </View>
  );
});

const Share = React.memo((props: ShareProps) => {
  return (
    <RightHeaderButton {...props}>
      <IconShare opacity="opaque" />
    </RightHeaderButton>
  );
});

const SpeakerOn = React.memo((props: SpeakerOnProps) => {
  return (
    <RightHeaderButton {...props}>
      <IconSpeakerOn opacity="opaque" color={globalStyleColors.colorTimerAction} />
    </RightHeaderButton>
  );
});

const SpeakerMuted = React.memo((props: SpeakerMutedProps) => {
  return (
    <RightHeaderButton {...props}>
      <IconSpeakerMuted opacity="opaque" color={props.tintColor} />
    </RightHeaderButton>
  );
});

const TextSize = React.memo((props: TextSizeProps) => {
  return (
    <RightHeaderButton {...props}>
      {/* Reducing the size of the icon just a bit to vertically match the hamburger menu icon */}
      <IconText opacity="opaque" color={props.tintColor} size={21} strokeWidth={1.5} />
    </RightHeaderButton>
  );
});

const FindUsers = React.memo((props: FindUsersProps) => {
  return (
    <RightHeaderButton {...props}>
      <IconSearchUsers opacity="opaque" />
    </RightHeaderButton>
  );
});

const TwoButtons = React.memo((props: TwoButtonsProps) => {
  const leftButton = renderRightHeader(props.left);
  const rightButton = renderRightHeader(props.right);

  const marginRightStyle = props.marginRight ? { marginRight: props.marginRight } : {};

  return (
    <View style={[{ flexDirection: "row", justifyContent: "space-between", alignItems: "center" }, marginRightStyle]}>
      {leftButton}
      {rightButton}
    </View>
  );
});

const ThreeButtons = React.memo((props: ThreeButtonsProps) => {
  const leftButton = renderRightHeader(props.left);
  const middleButton = renderRightHeader(props.middle);
  const rightButton = renderRightHeader(props.right);

  const marginRightStyle = props.marginRight ? { marginRight: props.marginRight } : {};

  return (
    <View style={[{ flexDirection: "row", justifyContent: "space-between", alignItems: "center" }, marginRightStyle]}>
      {leftButton}
      {middleButton}
      {rightButton}
    </View>
  );
});

const RightHeaderTextButton = React.memo(
  (props: {
    onPress: () => void;
    buttonText: string;
    tintColor?: string;
    marginRight?: number;
    disabled?: boolean;
  }) => {
    return (
      <RightHeaderButton onPress={props.onPress} marginRight={props.marginRight} disabled={props.disabled}>
        <TBody color={props.tintColor}>{props.buttonText}</TBody>
      </RightHeaderButton>
    );
  }
);

const RightHeaderButton = React.memo(
  (props: PropsWithChildren<{ onPress: () => void; marginRight?: number; disabled?: boolean }>) => {
    const marginRightStyle = props.marginRight ? { marginRight: props.marginRight } : {};

    return (
      <Pressable
        style={[styles.rightHeaderButton, marginRightStyle]}
        singlePress
        onPress={props.onPress}
        disabled={props.disabled}
      >
        {props.children}
      </Pressable>
    );
  }
);

const styles = StyleSheet.create({
  rightHeaderButton: {
    paddingHorizontal: globalStyleConstants.unitSize,
    paddingRight: 8,
  },
});
