import React, { useCallback, useMemo, useState } from "react";
import { NavApi } from "../../navigation/ScreenContainer";
import { RecipeId, RecipeSource, UserRecipeId } from "@eatbetter/recipes-shared";
import { Haptics } from "../Haptics";
import { navTree } from "../../navigation/NavTree";
import { archiveRecipe } from "../../lib/recipes/RecipesThunks";
import { OptionsMenu, OptionsMenuItem } from "../OptionsMenu";
import { Alert } from "../Alert/Alert";
import { useDispatch } from "../../lib/redux/Redux";
import { useBottomSheet } from "../../screens/BottomSheetScreen";
import { analyticsEvent } from "../../lib/analytics/AnalyticsThunks";
import {
  reportLibraryRecipeContextMenuItemTapped,
  reportUrlRecipeOpenedExternally,
} from "../../lib/analytics/AnalyticsEvents";
import {
  useIsOwnUserRecipe,
  useRecipeNotes,
  useRecipeRating,
  useRecipeSourceType,
} from "../../lib/recipes/RecipesSelectors";
import { UrlString, bottomThrow } from "@eatbetter/common-shared";
import { openWebpage } from "../../lib/util/WebUtil";
import { useRecipeTags } from "../../lib/recipes/RecipeTagSelectors";
import { useRecipeTagsPreview } from "./RecipeTags";
import { RecipeRatingEdit, RecipeRatingEditSheet, recipeRatingEditSheetHeight } from "./RecipeRating";
import { usePaywallStatus } from "../PaywallDetector";
import { displayUnexpectedErrorAndLog } from "../../lib/Errors";

const strings = {
  notes: "My Notes",
  tags: "My Tags",
  rating: "My Rating",
  share: "Share",
  editRecipe: "Edit Recipe",
  openExternal: "Open in External Browser",
  reportIssue: "Report a Recipe Issue",
  archiveRecipe: "Delete Recipe",
  archiveAlert: {
    title: "Delete Recipe",
    body: "Are you sure you want to delete this recipe?",
  },
};

export function getRecipeDetailOptionsHeight(args: {
  recipeSourceType: RecipeSource["type"] | undefined;
  isOwnUserRecipe: boolean;
}) {
  const firstItemMenuHeight = 80;
  const incrementalItemHeight = 60;

  const recipeSourceType = args.recipeSourceType ?? "user";

  const getHeight = (itemCount: number) => firstItemMenuHeight + itemCount * (incrementalItemHeight - 1);

  switch (recipeSourceType) {
    case "url":
      return getHeight(6);
    case "book":
      return getHeight(5);
    case "user":
      if (args.isOwnUserRecipe) {
        return getHeight(3);
      }
      return getHeight(4);
    case "userPhoto":
      ////PHOTO-INGESTION-TODO
      return getHeight(3);
    default:
      bottomThrow(recipeSourceType);
  }
}

interface Props {
  recipeId: UserRecipeId;
  sourceRecipeId?: RecipeId;
  openExternalUrl?: UrlString;
  nav: NavApi;
}

export const RecipeDetailOptions = React.memo((props: Props) => {
  const dispatch = useDispatch();
  const bottomSheet = useBottomSheet();
  const recipeSourceType = useRecipeSourceType(props.recipeId);
  const recipeNotes = useRecipeNotes(props.recipeId);
  const recipeTags = useRecipeTags(props.recipeId);
  const recipeTagsPreview = useRecipeTagsPreview(recipeTags);
  const recipeRating = useRecipeRating(props.recipeId);
  const isOwnUserRecipe = useIsOwnUserRecipe(props.recipeId);

  const [waitingArchiveRecipe, setWaitingArchiveRecipe] = useState(false);

  const { paywallStatus, paywallIsUp } = usePaywallStatus(props.recipeId);

  const onPressNotes = useCallback(() => {
    bottomSheet?.closeSheetAndGoBack();
    dispatch(analyticsEvent(reportLibraryRecipeContextMenuItemTapped({ action: "notes" })));
    setTimeout(
      () =>
        props.nav.goTo("push", navTree.get.screens.recipeNotesEdit, {
          recipeEditFieldLocation: "recipeDetail",
          recipeId: props.recipeId,
        }),
      300
    );
  }, [bottomSheet, props.nav.goTo, props.recipeId]);

  const onPressTags = useCallback(() => {
    bottomSheet?.closeSheetAndGoBack();
    dispatch(analyticsEvent(reportLibraryRecipeContextMenuItemTapped({ action: "tags" })));
    setTimeout(
      () =>
        props.nav.goTo("push", navTree.get.screens.recipeTagsEdit, {
          recipeEditFieldLocation: "recipeDetail",
          recipeId: props.recipeId,
        }),
      300
    );
  }, [bottomSheet, props.nav.goTo, props.recipeId]);

  const onPressRating = useCallback(() => {
    bottomSheet?.closeSheetAndGoBack();
    dispatch(analyticsEvent(reportLibraryRecipeContextMenuItemTapped({ action: "rating" })));
    setTimeout(
      () =>
        props.nav.modal(navTree.get.screens.bottomSheet, {
          height: recipeRatingEditSheetHeight,
          content: <RecipeRatingEditSheet recipeId={props.recipeId} />,
        }),
      300
    );
  }, [bottomSheet, props.nav.modal, props.recipeId]);

  const onEdit = useCallback(() => {
    bottomSheet?.closeSheetAndGoBack();
    dispatch(analyticsEvent(reportLibraryRecipeContextMenuItemTapped({ action: "editRecipe" })));
    setTimeout(() => props.nav.goTo("push", navTree.get.screens.recipeEdit, { recipeId: props.recipeId }), 300);
  }, [bottomSheet, props.nav.goTo, props.recipeId]);

  const onOpenExternal = useCallback(() => {
    if (!props.openExternalUrl) {
      displayUnexpectedErrorAndLog("Open Externally called but openExternalUrl is falsy", {}, { props });
      return;
    }
    bottomSheet?.closeSheetAndGoBack();
    dispatch(analyticsEvent(reportUrlRecipeOpenedExternally({ url: props.openExternalUrl, paywallStatus })));
    openWebpage(props.openExternalUrl);
  }, [bottomSheet, dispatch, props.openExternalUrl, paywallStatus]);

  const onPressReportIssue = useCallback(() => {
    bottomSheet?.closeSheetAndGoBack();
    dispatch(analyticsEvent(reportLibraryRecipeContextMenuItemTapped({ action: "reportIssue" })));
    setTimeout(() => props.nav.goTo("push", navTree.get.screens.reportRecipeIssue, { recipeId: props.recipeId }), 300);
  }, [bottomSheet, props.nav.goTo, props.recipeId]);

  const promptDelete = useCallback(() => {
    Alert.alert(strings.archiveAlert.title, strings.archiveAlert.body, [
      {
        type: "cancel",
        onPress: () => {},
      },
      {
        type: "delete",
        onPress: async () => {
          await dispatch(archiveRecipe({ recipeId: props.recipeId }, setWaitingArchiveRecipe));
          Haptics.feedback("operationSucceeded");
          bottomSheet?.closeSheetAndGoBack();
        },
      },
    ]);
  }, [props.recipeId, setWaitingArchiveRecipe, dispatch, bottomSheet]);

  const onArchive = useCallback(() => {
    promptDelete();
  }, [bottomSheet, promptDelete]);

  const ratingPreview = useMemo(() => {
    return <RecipeRatingEdit rating={recipeRating} />;
  }, [recipeRating]);

  const showEditRecipe = recipeSourceType !== "book";

  return (
    <OptionsMenu>
      <OptionsMenuItem icon="note" isFirst text={strings.notes} preview={recipeNotes} onPress={onPressNotes} />
      <OptionsMenuItem icon="tag" text={strings.tags} preview={recipeTagsPreview} onPress={onPressTags} />
      {!isOwnUserRecipe && (
        <OptionsMenuItem icon="rating" text={strings.rating} onPress={onPressRating} preview={ratingPreview} />
      )}
      <OptionsMenuItem
        icon="edit"
        text={strings.editRecipe}
        onPress={onEdit}
        disabled={paywallIsUp || !showEditRecipe}
      />
      {recipeSourceType === "url" && (
        <OptionsMenuItem
          icon="openExternal"
          text={strings.openExternal}
          onPress={onOpenExternal}
          disabled={!props.openExternalUrl}
        />
      )}
      {recipeSourceType !== "user" && (
        <OptionsMenuItem icon="flag" text={strings.reportIssue} onPress={onPressReportIssue} />
      )}
      <OptionsMenuItem waiting={waitingArchiveRecipe} icon="delete" text={strings.archiveRecipe} onPress={onArchive} />
    </OptionsMenu>
  );
});
