import { useQueryStringParam, useStringParam } from "../lib/ParamUtils";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  RecipeStoreDocument,
  RecipeStoreDocuments,
  StoredAiParserResult,
  StoredHtml,
  StoredParsedHtml,
} from "@eatbetter/recipes-server/dist/internal/RecipeRawDataStore";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "../lib/AdminRedux";
import { getRecipeStoreDocs } from "../lib/recipes/AdminRecipesThunks";
import {
  displayUnexpectedErrorAndLog,
  FlexedSpinner,
  globalStyleConstants,
  Pressable,
  Spacer,
  TBody,
  THeading1,
  THeading2,
  TSecondary,
} from "@eatbetter/ui-shared";
import { RecipeId } from "@eatbetter/recipes-shared";
import { AdminScreenView } from "../components/AdminScreenView";
import { AdminRecipesScreenIdNav } from "./AdminRecipesScreen";
import { View } from "react-native";
import ReactJson from "react-json-view";
import { bottomThrow, daysBetween, defaultTimeProvider } from "@eatbetter/common-shared";
import { Divider } from "antd";
import { ImperfectMatch } from "@eatbetter/recipes-server/dist/internal/Hallucination";

const recipeIdParam = ":recipeId";
const s3UrlParam = "s3Url";
export const AdminRecipeDocumentsScreenNav = {
  getPath: (recipeId?: string, s3Url?: string) =>
    `/recipes/${recipeId ?? recipeIdParam}/documents${s3Url ? `?${s3UrlParam}=${s3Url}` : ""}`,
};

export const AdminRecipeDocumentsScreen = () => {
  const recipeId = useStringParam<RecipeId>(recipeIdParam).required();
  const [data, setData] = useState<RecipeStoreDocuments | undefined>();
  const selectedDocumentUrl = useQueryStringParam(s3UrlParam).optional();
  const dispatch = useDispatch();
  const nav = useNavigate();

  useEffect(() => {
    dispatch(getRecipeStoreDocs(recipeId))
      .then(setData)
      .catch(err => {
        displayUnexpectedErrorAndLog("Error dispatching getRecipeStoreDocs", err);
      });
  }, [recipeId, dispatch, setData]);

  const onDocSelected = (s3Url: string) => {
    nav(AdminRecipeDocumentsScreenNav.getPath(recipeId, s3Url));
  };

  const selectedDoc = useMemo(() => {
    return data?.documents.find(d => d.s3Url === selectedDocumentUrl);
  }, [data, selectedDocumentUrl]);

  if (!data) {
    return <FlexedSpinner />;
  }

  return (
    <AdminScreenView>
      <THeading1>
        Documents for <Link to={AdminRecipesScreenIdNav.getPath(recipeId)}>{recipeId}</Link>
      </THeading1>
      <Spacer vertical={2} />
      <View style={{ flexDirection: "row", flex: 1 }}>
        <View style={{ width: 250, borderRightWidth: 0.1, paddingRight: globalStyleConstants.unitSize }}>
          <DocList data={data} onClick={onDocSelected} selectedS3Url={selectedDocumentUrl} />
        </View>
        <View style={{ paddingLeft: globalStyleConstants.unitSize, flex: 1 }}>
          {!selectedDoc && <TBody>No doc selected</TBody>}
          {selectedDoc && (
            <>
              <THeading1>{getType(selectedDoc)}</THeading1>
              <TBody>{new Date(selectedDoc.time).toLocaleString()}</TBody>
              {selectedDoc.type === "storedHtml" && <StoredHtmlComponent doc={selectedDoc} />}
              {selectedDoc.type === "parsedHtml" && <ParsedComponent doc={selectedDoc} />}
              {selectedDoc.type === "aiParserResult" && <AiParsedComponent doc={selectedDoc} />}
            </>
          )}
        </View>
      </View>
    </AdminScreenView>
  );
};

const StoredHtmlComponent = (props: { doc: StoredHtml }) => {
  return (
    <View>
      <TBody>Signature: {shortSig(props.doc.htmlSignature)}</TBody>
      <TBody>
        <a href={props.doc.url} target="_blank" rel="noreferrer">
          {props.doc.url}
        </a>
      </TBody>
      <Divider />
      <pre>{props.doc.html}</pre>
    </View>
  );
};

const ParsedComponent = (props: { doc: StoredParsedHtml }) => {
  return (
    <View>
      <TBody>Html Signature: {shortSig(props.doc.htmlSignature)}</TBody>
      <Divider />
      <ReactJson
        src={props.doc.parsed}
        displayDataTypes={false}
        displayObjectSize={false}
        quotesOnKeys={false}
        enableClipboard
        collapsed={false}
      />
    </View>
  );
};

const AiParsedComponent = (props: { doc: StoredAiParserResult }) => {
  const audit = props.doc.result.meta.audit;
  return (
    <View>
      <TBody>Html Signature: {shortSig(props.doc.htmlSignature ?? "")}</TBody>
      {audit && (
        <>
          <Divider />
          <TBody>Score: {audit.matchScore.toFixed(2)}</TBody>
          <TBody>Perfect matches: {audit.perfectMatchCount}</TBody>
          <TBody>Imperfect matches: {audit.imperfectMatches.length}</TBody>
          {audit.imperfectMatches.length > 0 && (
            <>
              <Spacer vertical={2} />
              <TSecondary>All strings normalized for comparison</TSecondary>
            </>
          )}
          {audit.imperfectMatches.map(im => (
            <ImperfectMatchComponent match={im} key={JSON.stringify(im)} />
          ))}
        </>
      )}
      <Divider />
      <ReactJson
        src={props.doc.result}
        displayDataTypes={false}
        displayObjectSize={false}
        quotesOnKeys={false}
        enableClipboard
        collapsed={false}
      />
      {!!props.doc.result.meta.input && (
        <>
          <Divider />
          <THeading2>Input</THeading2>
          <pre>{props.doc.result.meta.input}</pre>
        </>
      )}
      {!!props.doc.result.meta.audit?.normalizedInput && (
        <>
          <Divider />
          <THeading2>Normalized Input</THeading2>
          <View>
            <span style={{ fontFamily: "courier" }}>{props.doc.result.meta.audit?.normalizedInput}</span>
          </View>
        </>
      )}
    </View>
  );
};

const ImperfectMatchComponent = (props: { match: ImperfectMatch }) => {
  return (
    <table style={{ marginTop: globalStyleConstants.unitSize }}>
      <tbody style={{ fontFamily: "courier" }}>
        <tr>
          <td style={{ width: 100 }}>Query</td>
          <td>{props.match.normalizedQuery}</td>
        </tr>
        <tr>
          <td>Closest</td>
          <td>{props.match.normalizedClosestMatch}</td>
        </tr>
        <tr>
          <td>Score</td>
          <td>{props.match.score.toFixed(2)}</td>
        </tr>
      </tbody>
    </table>
  );
};

const DocList = (props: { data: RecipeStoreDocuments; onClick: (s3Url: string) => void; selectedS3Url?: string }) => {
  return (
    <View>
      {props.data.documents.map(doc => (
        <DocListItem doc={doc} onClick={props.onClick} selected={props.selectedS3Url === doc.s3Url} key={doc.s3Url} />
      ))}
    </View>
  );
};

const DocListItem = (props: { doc: RecipeStoreDocument; onClick: (s3Url: string) => void; selected: boolean }) => {
  const { doc } = props;

  const onClick = useCallback(() => {
    props.onClick(doc.s3Url);
  }, [props.onClick, doc.s3Url]);

  const opacity = props.selected ? 1 : 0.6;

  return (
    <Pressable onPress={onClick}>
      <View style={{ marginBottom: globalStyleConstants.unitSize, opacity }}>
        <TBody fontWeight="medium">{getType(doc)}</TBody>
        <TSecondary>{new Date(doc.time).toLocaleString()}</TSecondary>
        <TSecondary>{`${daysBetween(defaultTimeProvider(), doc.time).toFixed(1)} days ago`}</TSecondary>
        {doc.type === "storedHtml" && (
          <>
            <TSecondary>Signature: {shortSig(doc.htmlSignature)}</TSecondary>
          </>
        )}
      </View>
    </Pressable>
  );
};

function shortSig(sig: string): string {
  return sig.substring(0, 8);
}

function getType(doc: RecipeStoreDocument): string {
  switch (doc.type) {
    case "storedHtml":
      switch (doc.source) {
        case "fetched":
          return "Server Html";
        case "admin":
          return "Admin Html";
        case "browserFetched":
          return "Browser Html";
        case "user":
          return "User Html";
        default:
          bottomThrow(doc.source);
      }
      //@ts-ignore-next-line make es-lint happy
      throw new Error("AdminRecipeDocumentScreen getType");
    case "parsedHtml":
      return "Parsed";
    case "aiParserResult":
      return "AI Parsed";
    default:
      bottomThrow(doc);
  }
}
