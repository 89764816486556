import { bottomLog } from "@eatbetter/common-shared";
import { ScreenView } from "../../components/ScreenView";
import { useShareData } from "../../lib/share/UseShareData";
import { useScreen } from "../../navigation/ScreenContainer";
import { RecipeId, RecipeInfo } from "@eatbetter/recipes-shared";
import { FlexedSpinner } from "../../components/Spinner";
import React, { PropsWithChildren, useCallback, useEffect, useMemo, useState } from "react";
import { Image, ImageSourcePropType, StyleSheet, View } from "react-native";
import { TBody, TSecondary } from "../../components/Typography";
import { SocialPost } from "@eatbetter/posts-shared";
import { Spacer } from "../../components/Spacer";
import { globalStyleColors, globalStyleConstants } from "../../components/GlobalStyles";
import { Gradient } from "../../components/Gradient/Gradient";
import { HeaderProps, useHeaderScrollAnimation, useScreenHeaderDimensions } from "../../components/ScreenHeaders";
import { ButtonRectangle, LeftAndRightButtons } from "../../components/Buttons";
import { Separator } from "../../components/Separator";
import { log } from "../../Log";
import { SocialPostCard } from "../../components/social/SocialPost";
import { ExternalSharedRecipeScreenProps, navTree } from "../../navigation/NavTree";
import { useAuthedUser } from "../../lib/system/SystemSelectors";
import Animated, { useAnimatedScrollHandler } from "react-native-reanimated";
import { Photo } from "../../components/Photo";
import { displayUnexpectedErrorAndLog } from "../../lib/Errors";
import { referralDataAvailable } from "../../lib/system/SystemSlice";
import { useDispatch } from "../../lib/redux/Redux";
import { ScreenHeaderBanner, screenHeaderBannerConstants } from "../../components/ScreenHeaderBanner";
import { signOutClicked } from "../../lib/system/SystemThunks";
import { PhotoRef } from "@eatbetter/photos-shared";
import { useShareActions } from "../../lib/share/UseShareActions";
import {
  reportSharedRecipeOrUserViewed,
  reportViewRecipeTappedOnShareScreen,
} from "../../lib/analytics/AnalyticsEvents";
import { analyticsEvent } from "../../lib/analytics/AnalyticsThunks";
import { openWebpage } from "../../lib/util/WebUtil";
import { ShareRecipeCard } from "../../components/ShareRecipeCard";
import { ScreenFooter } from "../../components/ScreenFooter";

const strings = {
  screenTitle: "deglaze",
  userInvitedYou: (userFirstName: string) => `${userFirstName} invited you to join Deglaze.`,
  userInvitedYouAction: "Join or sign in",
  userSharedRecipe: (userFirstName: string) => `${userFirstName} shared a recipe with you`,
  seeWhatUserIsCooking: (userFirstName: string) => `Check out what ${userFirstName}'s been cooking:`,
  userStoresEverything: (_userFirstName: string) =>
    "All your recipes in one place.\nOrganized grocery lists in seconds.",
  browseAllPosts: (userFirstName: string) => `See all of ${userFirstName}'s posts`,
  goToRecipe: "Go to recipe",
  saveToLibrary: "Save to library",
  inLibrary: "Saved!",
  signUp: "Sign up",
  createFreeAccount: "Create free account",
  loadingError: "Whoops! We're having trouble connecting right now. Refresh the page to try again.",
  metaDescription: "Deglaze: Cooking, simplified.",
  recipeSaved: "Recipe saved to your library!",
};

const sessionSaveKey = (r: RecipeId) => `save-${r}`;

export const ExternalSharedRecipeScreenComponent = (props: ExternalSharedRecipeScreenProps) => {
  const { userId, sharedRecipeId, sourceRecipeId } = props;

  const dispatch = useDispatch();
  const screen = useScreen();
  const shareData = useShareData(userId, sharedRecipeId);
  const authedUser = useAuthedUser();

  const { recipeSaved, waitingForSave, doSave, onPressSave, onPressSignUp, onRecipeSaved } = useShareActions({
    sharingUserId: userId,
    sharedRecipeId: props.sharedRecipeId,
    sharedSourceRecipeID: props.sourceRecipeId,
    viewingUserHasRecipe: shareData.data?.viewingUserHasRecipe ?? false,
  });

  // only show the banner when we actually save upon return. Otherwise, the user will see the button change state
  const [recipeSavedBannerClosed, setRecipeSavedBannerClose] = useState(props.action !== "save");

  const showRecipeSavedBanner = recipeSaved && !recipeSavedBannerClosed;

  useEffect(() => {
    // Store the info in case it's a new user about to sign up
    dispatch(
      referralDataAvailable({ referredBy: userId, sharedRecipeId: sharedRecipeId, sourceRecipeId: sourceRecipeId })
    );
  }, [userId, sharedRecipeId, sourceRecipeId]);

  useEffect(() => {
    if (shareData.data) {
      const event = reportSharedRecipeOrUserViewed({
        sharedRecipeId: props.sharedRecipeId,
        sourceRecipeId: props.sourceRecipeId,
        recipe: shareData.data?.recipe,
        sharingUserUsername: shareData.data.user.username,
        sharingUserId: shareData.data.user.userId,
      });
      dispatch(analyticsEvent(event));
    }
  }, [!!shareData.data]);

  useEffect(() => {
    // we save automatically if the action tells us to, we have a recipe, it hasn't already been saved, and the
    // share data has returned and told us the user doesn't have it.
    if (
      props.action === "save" &&
      sharedRecipeId &&
      !recipeSaved &&
      shareData.data &&
      !shareData.data.viewingUserHasRecipe
    ) {
      // one more check
      // confirm the user actually took this action and it's not a "malicious" URL
      // I suppose we could just use this session var as the sole decision point, but having it on the
      // URL makes it apparent from the URL
      const sessionVar = sessionStorage.getItem(sessionSaveKey(sharedRecipeId));
      if (!sessionVar) {
        log.warn("ExternalSharedRecipeScreen action=save, but session var not found", {
          recipeId: sharedRecipeId,
          userId,
        });
        return;
      }

      doSave().catch(err => {
        log.errorCaught(
          "Error calling doSave in ExternalSharedRecipe useEffect. This should not happen since doSave catches and should never reject",
          err
        );
      });
    }
  }, [sharedRecipeId, doSave, props.action, recipeSaved, shareData.data]);

  const onPressCloseRecipeSavedBanner = useCallback(() => {
    setRecipeSavedBannerClose(true);
  }, [setRecipeSavedBannerClose]);

  const onPressGoToRecipe = useCallback(() => {
    dispatch(analyticsEvent(reportViewRecipeTappedOnShareScreen()));

    if (!shareData.data?.recipe) {
      log.error("Share recipe external: 'Go to recipe' pressed but recipe is undefined", {
        shareData: shareData.data,
      });
      return;
    }

    switch (shareData.data.recipe.source.type) {
      case "url": {
        openWebpage(shareData.data.recipe.source.url);
        return;
      }
      case "user": {
        if (!("type" in shareData.data.recipe && shareData.data.recipe.type === "userRecipe")) {
          displayUnexpectedErrorAndLog("User recipe is missing", undefined, { shareData: shareData.data });
          return;
        }
        screen.nav.goTo("push", navTree.get.screens.externalSharedUserRecipeDetail, {
          sharingUserId: userId,
          recipe: shareData.data.recipe,
          viewingUserHasRecipe: recipeSaved,
          onRecipeSaved,
        });
        return;
      }
      case "book": {
        screen.nav.goTo("push", navTree.get.screens.externalSharedBookRecipeDetail, {
          sharingUserId: userId,
          recipe: shareData.data.recipe,
          sourceRecipeId: props.sourceRecipeId,
          recipeId: props.sharedRecipeId,
          viewingUserHasRecipe: recipeSaved,
        });
        return;
      }
      case "userPhoto": {
        //PHOTO-INGESTION-TODO
        return;
      }
      default: {
        bottomLog(shareData.data.recipe.source, "shareData.data.recipe.source.type", log);
        return;
      }
    }
  }, [
    shareData.data,
    screen.nav,
    dispatch,
    onRecipeSaved,
    props.sourceRecipeId,
    props.sharedRecipeId,
    recipeSaved,
    userId,
  ]);

  const renderRecipeSavedBanner = useCallback(() => {
    return (
      <>
        {showRecipeSavedBanner && (
          <ScreenHeaderBanner message={strings.recipeSaved} onPressClose={onPressCloseRecipeSavedBanner} />
        )}
      </>
    );
  }, [showRecipeSavedBanner, onPressCloseRecipeSavedBanner]);

  const getRecipeSavedBannerHeight = useCallback(() => {
    if (!showRecipeSavedBanner) {
      return 0;
    }
    return screenHeaderBannerConstants.height;
  }, [showRecipeSavedBanner]);

  const [animationProgress, onScroll] = useHeaderScrollAnimation();

  const screenHeader = useMemo<HeaderProps>(() => {
    return {
      title: strings.screenTitle,
      titleColor: globalStyleColors.blackSoft,
      type: "custom",
      style: "tabRoot",
      right: authedUser
        ? { type: "signOut", onPress: async () => dispatch(signOutClicked(screen.nav)) }
        : { type: "signUp", onPress: onPressSignUp },
      animationConfig: {
        animationProgress,
        disableRetract: true,
        blurBackgroundThreshold: globalStyleConstants.unitSize,
      },
      subHeaderComponent: {
        render: renderRecipeSavedBanner,
        getHeight: getRecipeSavedBannerHeight,
      },
    };
  }, [
    authedUser,
    onPressSignUp,
    animationProgress,
    renderRecipeSavedBanner,
    getRecipeSavedBannerHeight,
    dispatch,
    screen.nav,
  ]);

  return (
    <ScreenView scrollView={false} paddingVertical={false} paddingHorizontal={false} header={screenHeader}>
      <ShareRecipeContainer onScroll={onScroll}>
        {shareData.status === "waiting" && <FlexedSpinner />}
        {shareData.status === "error" && <LoadingError />}
        {shareData.status === undefined && (
          <>
            {!shareData.data.recipe && (
              <UserInvitedYou
                userFirstName={shareData.data.userFirstName}
                userPhoto={shareData.data.user.photo}
                onPressJoin={onPressSignUp}
              />
            )}
            {!!shareData.data.recipe && (
              <UserSharedRecipeWithYou
                userFirstName={shareData.data.userFirstName}
                userPhoto={shareData.data.user.photo}
                recipeInfo={shareData.data.recipe}
                isAuthed={!!authedUser}
                onPressGoToRecipe={onPressGoToRecipe}
                onPressSaveToLibrary={onPressSave}
                recipeSaved={recipeSaved}
                waitingForSave={waitingForSave}
              />
            )}
            {!authedUser && (
              <WhyDeglaze
                userFirstName={shareData.data.userFirstName}
                userPosts={shareData.data.posts}
                onPressSignUp={onPressSignUp}
                showSocialPreview={!props.sharedRecipeId}
              />
            )}
          </>
        )}
      </ShareRecipeContainer>
    </ScreenView>
  );
};

const UserSharedRecipeWithYou = React.memo(
  (props: {
    userFirstName: string;
    isAuthed: boolean;
    userPhoto?: PhotoRef;
    recipeInfo: RecipeInfo;
    onPressGoToRecipe: () => void;
    onPressSaveToLibrary: () => void;
    recipeSaved: boolean;
    waitingForSave: boolean;
  }) => {
    return (
      <>
        <Heading text={strings.userSharedRecipe(props.userFirstName)} avatar={props.userPhoto} />
        <Spacer vertical={1.5} />
        <ShareRecipeCard recipeInfo={props.recipeInfo} onPress={props.onPressGoToRecipe} />
        <View style={styles.screenPaddingHorizontal}>
          <Spacer vertical={1.5} />
          {!!props.recipeInfo.book && (
            <ButtonRectangle
              size="large"
              type={props.isAuthed ? "submit" : "secondary"}
              title={strings.goToRecipe}
              onPress={props.onPressGoToRecipe}
            />
          )}
          {!props.recipeInfo.book && (
            <LeftAndRightButtons
              buttonSize="large"
              leftButtonText={strings.goToRecipe}
              onPressLeftButton={props.onPressGoToRecipe}
              rightButtonText={props.recipeSaved ? strings.inLibrary : strings.saveToLibrary}
              onPressRightButton={props.onPressSaveToLibrary}
              rightButtonDisabled={props.recipeSaved}
            />
          )}
          <Spacer vertical={1.5} />
        </View>
      </>
    );
  }
);

const UserInvitedYou = React.memo((props: { userFirstName: string; userPhoto?: PhotoRef; onPressJoin: () => void }) => {
  return (
    <View>
      <Heading text={strings.userInvitedYou(props.userFirstName)} avatar={props.userPhoto} />
      <Spacer vertical={1.5} />
      <CallToActionButton text={strings.userInvitedYouAction} onPress={props.onPressJoin} />
      <Spacer vertical={2} />
    </View>
  );
});

const WhyDeglaze = React.memo(
  (props: {
    userFirstName: string;
    userPosts: SocialPost[];
    onPressSignUp: () => void;
    showSocialPreview: boolean;
  }) => {
    return (
      <>
        {!!props.showSocialPreview && (
          <SeeWhatUserIsCooking
            userFirstName={props.userFirstName}
            posts={props.userPosts}
            onPressSeeAllPosts={props.onPressSignUp}
            onPressPostAction={props.onPressSignUp}
          />
        )}
        <RecipesAndGroceriesDemo userFirstName={props.userFirstName} onPressSignUp={props.onPressSignUp} />
      </>
    );
  }
);

const SeeWhatUserIsCooking = React.memo(
  (props: {
    userFirstName: string;
    posts: SocialPost[];
    onPressSeeAllPosts: () => void;
    onPressPostAction: () => void;
  }) => {
    const [firstPost, secondPost] = props.posts;

    if (props.posts.length < 1) {
      return null;
    }

    return (
      <>
        <View style={{ overflow: "hidden" }}>
          <View style={styles.screenPaddingHorizontal}>
            <Separator orientation="row" />
          </View>
          <Spacer vertical={1.5} />
          {!!firstPost && (
            <View>
              <Heading text={strings.seeWhatUserIsCooking(props.userFirstName)} />
              <Spacer vertical={0.5} />
              <SocialPostCard
                post={firstPost}
                liked={false}
                onPressShareButton={props.onPressPostAction}
                onPressPost={props.onPressPostAction}
                onPressCommentButton={props.onPressPostAction}
                onPressLikeButton={props.onPressPostAction}
                onPressLikesBar={props.onPressPostAction}
                isDetailView={false}
              />
            </View>
          )}
          {!!secondPost && (
            <View>
              <Spacer vertical={0.5} />
              <SocialPostCard
                post={secondPost}
                liked={false}
                onPressShareButton={props.onPressPostAction}
                onPressPost={props.onPressPostAction}
                onPressCommentButton={props.onPressPostAction}
                onPressLikeButton={props.onPressPostAction}
                onPressLikesBar={props.onPressPostAction}
                isDetailView={false}
              />
            </View>
          )}
          <Spacer vertical={4} />
          <View
            style={[
              styles.snapToBottom,
              { height: 400, borderBottomLeftRadius: 20, borderBottomRightRadius: 20, overflow: "hidden" },
            ]}
          >
            <Gradient type="vertical" fromColor="transparent" toColor={globalStyleColors.colorGreyLight} />
          </View>
          <View style={[styles.snapToBottom, { bottom: globalStyleConstants.unitSize }]}>
            <CallToActionButton text={strings.browseAllPosts(props.userFirstName)} onPress={props.onPressSeeAllPosts} />
          </View>
        </View>
        <Spacer vertical={1} />
      </>
    );
  }
);

const RecipesAndGroceriesDemo = React.memo((props: { userFirstName: string; onPressSignUp: () => void }) => {
  return (
    <View>
      <View style={styles.screenPaddingHorizontal}>
        <Separator orientation="row" />
      </View>
      <Spacer vertical={1.5} />
      <Heading text={strings.userStoresEverything(props.userFirstName)} />
      <Spacer vertical={2} />
      <View style={{ alignItems: "center" }}>
        <View style={{ width: "100%", paddingBottom: 60, minWidth: 300, maxWidth: 460 }}>
          <View style={styles.screenPaddingHorizontal}>
            <AppScreenshot imageSource={require("../../assets/images/recipe_library.jpeg")} />
          </View>
          <View style={{ position: "absolute", right: 20, top: 60 }}>
            <AppScreenshot imageSource={require("../../assets/images/grocery_list.jpeg")} />
          </View>
        </View>
      </View>
      <Spacer vertical={3} />
      <CallToActionButton text={strings.createFreeAccount} onPress={props.onPressSignUp} />
    </View>
  );
});

const CallToActionButton = React.memo((props: { text: string; onPress: () => void }) => {
  return (
    <View style={styles.screenPaddingHorizontal}>
      <ButtonRectangle title={props.text} type="submit" size="large" shadow onPress={props.onPress} />
    </View>
  );
});

const ShareRecipeContainer = React.memo(
  (props: PropsWithChildren<{ onScroll: ReturnType<typeof useAnimatedScrollHandler> }>) => {
    const { headerHeight } = useScreenHeaderDimensions();

    return (
      <Animated.ScrollView
        showsVerticalScrollIndicator={false}
        contentContainerStyle={{
          flex: 1,
          justifyContent: "space-between",
          paddingTop: headerHeight + 0.5 * globalStyleConstants.unitSize,
        }}
        onScroll={props.onScroll}
        scrollEventThrottle={16}
      >
        {props.children}
        <Spacer vertical={8} />
        <ScreenFooter showLogo />
      </Animated.ScrollView>
    );
  }
);

const Heading = React.memo((props: { text: string; avatar?: PhotoRef }) => {
  return (
    <View style={[styles.screenPaddingHorizontal, { flexDirection: "row", alignItems: "center" }]}>
      {!!props.avatar && (
        <>
          <Photo source={props.avatar} style="avatarMedium" sourceSize="w1290" />
          <Spacer horizontal={1} />
        </>
      )}
      <TBody enableFontScaling="upOnly" scale={1.1}>
        {props.text}
      </TBody>
    </View>
  );
});

const AppScreenshot = React.memo((props: { imageSource: ImageSourcePropType }) => {
  return (
    <View style={styles.appScreenshotWrap}>
      <Image resizeMode="stretch" source={props.imageSource} style={styles.appScreenshot} />
    </View>
  );
});

const LoadingError = React.memo(() => {
  return (
    <View style={[styles.flexedAndCentered, styles.screenPaddingHorizontal]}>
      <TSecondary align="center">{strings.loadingError}</TSecondary>
    </View>
  );
});

const styles = StyleSheet.create({
  flexedAndCentered: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  screenPaddingHorizontal: {
    paddingHorizontal: 20,
  },
  snapToBottom: {
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
  },
  appScreenshot: {
    flex: 1,
    borderRadius: 25,
  },
  appScreenshotWrap: {
    backgroundColor: globalStyleColors.colorGreyLight,
    shadowColor: "black",
    shadowOpacity: 0.25,
    shadowRadius: 12,
    shadowOffset: { width: 0, height: 4 },
    width: 200,
    borderRadius: 25,
    height: 432,
  },
});
