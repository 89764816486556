import React, { useState } from "react";
import { RecipeTagManifest, UserRecipe, AppUserRecipe } from "@eatbetter/recipes-shared";
import { View } from "react-native";
import {
  THeading2,
  Spacer,
  globalStyleConstants,
  Photo,
  TBody,
  ButtonRectangle,
  displayUnexpectedErrorAndLog,
  Pressable,
  globalStyleColors,
  CurrentEnvironment,
} from "@eatbetter/ui-shared";
import { NvpTable } from "./NvpTable";
import { Link, useNavigate } from "react-router-dom";
import { AdminRecipesScreenIdNav } from "../screens/AdminRecipesScreen";
import {
  AuthorComponent,
  CategorizedIngredients,
  IndexComponent,
  Ingredients,
  Instructions,
  PublisherComponent,
  TagsComponent,
  TimeComponent,
  YieldComponent,
} from "./AdminRecipeComponents";
import { ParsedIngredientSummary } from "@eatbetter/items-shared";
import { AdminRecipeEditScreenNav } from "../screens/AdminRecipeEditScreen";
import { deleteUserRecipe, reprocessUserUrlRecipe, setUserRecipeFullAccess } from "../lib/recipes/AdminRecipesThunks";
import { useDispatch } from "../lib/AdminRedux";
import { AdminRecipeDocumentsScreenNav } from "../screens/AdminRecipeDocumentsScreen";
import { getUrlForAsset, PhotoRef } from "@eatbetter/photos-shared";

const editConfirmMessage =
  "You are about to edit a user recipe on behalf of the user! If you save the recipe, no future " +
  "changes to the source recipe will be propagated to the user recipe.";

const deleteConfirmMessage = "Are you sure you want to delete this user recipe?";

export const UserRecipeView = React.memo(
  (props: {
    recipe: UserRecipe | AppUserRecipe;
    parsed?: ParsedIngredientSummary[];
    tagManifest?: RecipeTagManifest;
    onRecipeUpdated?: (r: AppUserRecipe) => void;
  }) => {
    const nav = useNavigate();
    const dispatch = useDispatch();
    const [waiting, setWaiting] = useState(false);

    const recipe = props.recipe;
    const externalSourceRecipe = !!recipe.sourceRecipeId && recipe.sourceRecipeId.startsWith("e:");

    const onEdit = () => {
      if (window.confirm(editConfirmMessage)) {
        nav(AdminRecipeEditScreenNav.getPath(recipe.id));
      }
    };

    const onReprocess = async () => {
      try {
        const updated = await dispatch(
          reprocessUserUrlRecipe({ userId: recipe.userId, recipeId: recipe.id }, setWaiting)
        );
        props.onRecipeUpdated?.(updated);
      } catch (err) {
        displayUnexpectedErrorAndLog("Error reprocessing user recipe", err);
      }
    };

    const onDelete = async () => {
      try {
        if (window.confirm(deleteConfirmMessage)) {
          const updated = await dispatch(
            deleteUserRecipe({ userId: recipe.userId, recipeId: recipe.id, version: recipe.version }, setWaiting)
          );
          props.onRecipeUpdated?.(updated);
        }
      } catch (err) {
        displayUnexpectedErrorAndLog("Error reprocessing user recipe", err);
      }
    };

    const onToggleFullAccess = async () => {
      if (recipe.ac) {
        throw new Error("Recipe is access checked. This should only be called for access checked false");
      }

      if (recipe.faInfo?.type === "legacy") {
        throw new Error("Full access via legacy cannot be changed");
      }

      try {
        const updated = await dispatch(
          setUserRecipeFullAccess({ recipeId: recipe.id, access: recipe.faInfo?.type === "adminRecipe" ? null : {} })
        );
        props.onRecipeUpdated?.(updated);
      } catch (err) {
        displayUnexpectedErrorAndLog("Error toggling full access", err);
      }
    };

    return (
      <View>
        <THeading2>[User Recipe] {recipe.title}</THeading2>
        <Spacer vertical={1} />
        {recipe.ac && (
          <View style={{ backgroundColor: "yellow" }}>
            <TBody>
              You are viewing the AppUserRecipe, which is the exact data that is sent down to the user. To view the full
              server representation of the recipe,{" "}
              <Link to={AdminRecipesScreenIdNav.getPath(recipe.id)} target="_blank">
                view it here
              </Link>
            </TBody>
          </View>
        )}
        <Spacer vertical={1} />
        <View style={{ flexDirection: "row", alignItems: "center" }}>
          <View style={{ width: globalStyleConstants.unitSize * 12 }}>
            <ButtonRectangle onPress={onEdit} type="submit" title="Edit" waiting={waiting} />
          </View>
          <Spacer horizontal={1} />
          <View>
            <ButtonRectangle onPress={onReprocess} type="submit" title="Reprocess" waiting={waiting} />
          </View>
          <Spacer horizontal={1} />
          <View>
            <ButtonRectangle onPress={onDelete} type="submit" title="Delete" waiting={waiting} />
          </View>
        </View>
        <Spacer vertical={1} />
        <table>
          <tbody>
            <tr>
              <td>
                <TBody fontWeight="heavy">Meta</TBody>
              </td>
            </tr>
            <NvpTable name="ID" value={recipe.id} />
            {externalSourceRecipe && (
              <NvpTable name="Source Recipe ID">
                <Link to={AdminRecipesScreenIdNav.getPath(recipe.sourceRecipeId)} target="_blank">
                  {recipe.sourceRecipeId}
                </Link>
              </NvpTable>
            )}
            {!externalSourceRecipe && <NvpTable name="Source Recipe ID" value={recipe.sourceRecipeId} />}
            <NvpTable name="Status" value={recipe.status} />
            <NvpTable name="Created" value={new Date(recipe.created).toString()} />
            <NvpTable
              name="Deleted"
              value={(recipe.deleted ?? false).toString()}
              backgroundColor={recipe.deleted ? "red" : undefined}
            />
            <NvpTable
              name="Archived"
              value={(recipe.archived ?? false).toString()}
              backgroundColor={recipe.archived ? "red" : undefined}
            />
            <NvpTable name="Access Checked" value={String(recipe.ac)} />
            {recipe.ac && <NvpTable name="Full Access" value={String(recipe.fa)} />}
            {!recipe.ac && (
              <NvpTable name="Full Access Info">
                <View style={{ flexDirection: "row", alignItems: "center" }}>
                  <TBody opacity="medium">{recipe.faInfo ? JSON.stringify(recipe.faInfo) : "undefined"}</TBody>
                  <Spacer horizontal={1} />
                  <Pressable onPress={onToggleFullAccess}>
                    <TBody color={globalStyleColors.colorTextLink}>
                      {recipe.faInfo?.type === "adminRecipe" ? "revoke" : "grant"}
                    </TBody>
                  </Pressable>
                </View>
              </NvpTable>
            )}
            <NvpTable name="User ID" value={recipe.userId} />
            <NvpTable name="Household ID" value={recipe.householdId ?? "-"} />
            <NvpTable name="Version" value={recipe.version} />
            <NvpTable name="Source" value={JSON.stringify(recipe.source)} />
            {recipe.source.type === "userPhoto" && (
              <NvpTable name="Source Photos">
                {recipe.source.photos.map((p, idx) => (
                  <SourcePhoto photo={p} key={idx} />
                ))}
              </NvpTable>
            )}
            <NvpTable
              name="Source Recipe Version"
              value={
                typeof recipe.sourceRecipeVersion === "object"
                  ? JSON.stringify(recipe.sourceRecipeVersion)
                  : recipe.sourceRecipeVersion ?? "-"
              }
            />
            <NvpTable name="Source Recipe Meta Version" value={recipe.sourceRecipeMetaVersion ?? "-"} />
            <NvpTable name="Edit Count" value={recipe.stats.edited ?? 0} />
            <NvpTable name="Add to List Count" value={recipe.stats.addedToList ?? 0} />
            <NvpTable name="Cooked Count" value={recipe.stats.cooked ?? 0} />
            <NvpTable name="Notes" value={recipe.notes?.text ?? ""} />
            <NvpTable name="Tags" value={JSON.stringify(recipe.tags)} />
            {!recipe.ac && (
              <NvpTable name="Search Indexing">
                <IndexComponent recipe={recipe} />
              </NvpTable>
            )}
            <NvpTable name="">
              <TBody>
                <Link to={AdminRecipeDocumentsScreenNav.getPath(recipe.id)} target="_blank">
                  Docs
                </Link>
              </TBody>
            </NvpTable>
            <tr>
              <td>
                <TBody fontWeight="heavy">Recipe</TBody>
              </td>
            </tr>
            <NvpTable name="Title">
              <THeading2>{recipe.title}</THeading2>
            </NvpTable>
            <NvpTable name="Photo">
              <View style={{ width: globalStyleConstants.unitSize * 24 }}>
                <Photo source={recipe.photo} style="fullWidthLarge" resizeMode="contain" sourceSize="w1290" />
              </View>
            </NvpTable>
            {!!recipe.photo && <NvpTable name="Photo type" value={recipe.photo.type} />}
            <NvpTable name="Author">
              <AuthorComponent author={recipe.author} />
            </NvpTable>
            <NvpTable name="Publisher">
              <PublisherComponent publisher={recipe.publisher} />
            </NvpTable>
            <NvpTable name="Description">
              <TBody>{recipe.description}</TBody>
            </NvpTable>
            <NvpTable name="Time">
              <TimeComponent time={recipe.time} />
            </NvpTable>
            <NvpTable name="Yield">
              <YieldComponent yield={recipe.recipeYield} />
            </NvpTable>
            {!!props.tagManifest && (
              <NvpTable name="Tags">
                <TagsComponent tags={recipe.tags} manifest={props.tagManifest} />
              </NvpTable>
            )}
            <NvpTable name="Ingredients">
              <Ingredients ingredients={recipe.ingredients} />
            </NvpTable>
            <NvpTable name="Instructions">
              <Instructions instructions={recipe.instructions} />
            </NvpTable>
            {props.parsed && (
              <NvpTable name="Categorized">
                <CategorizedIngredients items={props.parsed} />
              </NvpTable>
            )}
          </tbody>
        </table>
      </View>
    );
  }
);

const SourcePhoto = (props: { photo: PhotoRef }) => {
  return (
    <a
      href={getUrlForAsset(CurrentEnvironment.configEnvironment(), props.photo, "original")}
      target="_blank"
      rel="noreferrer"
    >
      <img src={getUrlForAsset(CurrentEnvironment.configEnvironment(), props.photo, "w288")} />
    </a>
  );
};
